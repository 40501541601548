import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Modal from 'react-modal';

// Встановлення елементу для модалки
Modal.setAppElement('#root');

const FeedbackModal = ({ isOpen, onRequestClose, initialPhone }) => {
  const form = useRef();
  const [currentPhone, setCurrentPhone] = useState(initialPhone); // стан для номера телефону

  useEffect(() => {
    setCurrentPhone(initialPhone); // Встановлює поточний номер телефону на основі initialPhone кожного разу, коли initialPhone змінюється
  }, [initialPhone]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_wwunj4y',
        'template_7fo1obp',
        form.current,
        'EuHmEbyzJMPzmZU_E'
      )
      .then(
        (result) => {
          console.log(result.text);
          onRequestClose();
          window.location.href = '/success.html';
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // Обробник подій для зміни номера телефону
  const handlePhoneChange = (e) => {
    setCurrentPhone(e.target.value);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      height: 'auto',
      padding: '20px', // додатковий внутрішній відступ
      boxSizing: 'border-box', // забезпечує правильний підрахунок ширини з урахуванням відступів
    },
    requiredLabel: {
      color: 'red',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Feedback Form"
      style={customStyles}
    >
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h2>Напишіть нам</h2>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <label className="col-form-label">
              Ім'я: <span style={customStyles.requiredLabel}>*</span>
            </label>
            <input className="form-control" type="text" name="name" required />
          </div>
          <div>
            <label className="col-form-label">
              Email: <span style={customStyles.requiredLabel}>*</span>
            </label>
            <input
              className="form-control"
              type="email"
              name="email"
              required
            />
          </div>
          <div>
            <label className="col-form-label">
              Номер телефону: <span style={customStyles.requiredLabel}>*</span>
            </label>
            <input
              className="form-control"
              type="tel"
              name="phone"
              value={currentPhone || ''}
              required
              onChange={handlePhoneChange} // додайте обробник подій
            />
          </div>
          <div>
            <label className="col-form-label">Повідомлення:</label>
            <textarea className="form-control" name="message" />
          </div>
          <input
            type="submit"
            value="Надіслати"
            className="btn btn-warning ms-3 mt-3"
          />
        </form>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
