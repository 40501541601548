import React, { useState } from 'react';
import FeedbackModal from './components/FeedbackModal';
import './App.css';
import MainMenu from './components/MainMenu';
import AboutUs from './components/AboutUs';
import Advantages from './components/Advantages';
import Tools from './components/Tools';
import Hero from './components/Hero';
import Marketing from './components/Marketing';
import Footer from './components/Footer';

function App() {
  const [isModal, setIsModal] = useState(false);

  const openModal = () => {
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  return (
    <main class="main" id="top">
      <MainMenu openModal={openModal} />
      <AboutUs />
      <Advantages />
      <Tools />
      <Hero />
      <Marketing />
      <Footer />
      {isModal && <FeedbackModal closeModal={closeModal} />}
    </main>
  );
}

export default App;
