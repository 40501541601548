import React from 'react';

function LegalModal({ isOpen, onClose, content }) {
  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div style={overlayStyles} onClick={handleOverlayClick}>
      <div style={modalStyles} onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose}>Закрити</button>
        <h2>{content.title}</h2>
        <p style={legalTextStyles}>{content.text}</p>
      </div>
    </div>
  );
}

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalStyles = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  width: '70%',
  maxWidth: '600px',
  marginTop: '100px',
};

const legalTextStyles = {
  whiteSpace: 'pre-line',
};

export default LegalModal;
