import React, { useState } from 'react';
import FeedbackModal from '../components/FeedbackModal';

function AboutUs() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <section className="pt-7">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 text-md-start text-center py-6">
            <h1 className="mb-4 fs-9 fw-bold">Надможливості SEO мислення</h1>
            <p className="mb-6 lead text-secondary">
              Глибоке розуміння SEO-стратегії та ефективність
              <br class="d-none d-xl-block" />
              наших методів призведе до підвищення
              <br class="d-none d-xl-block" />
              трафіку на ваш сайт!
            </p>
            <div className="text-center text-md-start">
              <a
                className="btn btn-warning me-3 btn-lg"
                href="#!"
                role="button"
                onClick={openModal}
              >
                Почати
              </a>
              <FeedbackModal isOpen={isModalOpen} onRequestClose={closeModal} />
              <a
                class="btn btn-link text-warning fw-medium"
                href="#!"
                role="button"
                data-bs-toggle="modal"
                data-bs-target="#popupVideo"
              >
                <span class="fas fa-play me-2"></span>Презентація{' '}
              </a>
            </div>
          </div>
          <div className="col-md-6 text-end">
            <img
              className="pt-7 pt-md-0 img-fluid"
              src="assets/img/hero/hero-img.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
