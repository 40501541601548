import React from 'react';

function Marketing() {
  return (
    <section class="pt-5" id="marketing">
      <div class="container">
        <h1 class="fw-bold fs-6 mb-3">Ціни на SEO оптимізацію сайтів</h1>
        <p class="mb-6 text-secondary">
          Оберіть оптимальний пакет для вашого бізнесу:
        </p>
        <div class="row">
          <div class="col-md-4 mb-4">
            <div class="card">
              <img
                class="card-img-top"
                src="assets/img/marketing/giraffe-1.png"
                alt=""
              />
              <div class="card mb-4">
                <div class="card-header bg-transparent">
                  <h3 class="fw-bold mb-0 bg-opacity">
                    <span>"Старт" 8 тис. грн/міс</span>
                  </h3>
                </div>

                <div class="card-body">
                  <h5 class="fw-bold">Підходить для:</h5>
                  <ul class="mb-3">
                    <li>Невеликих сайтів або стартапів.</li>
                    <li>
                      Компаній, які тільки розпочинають своє просування в
                      Інтернеті.
                    </li>
                  </ul>

                  <h5 class="fw-bold">Основні характеристики:</h5>
                  <ul>
                    <li>Оптимізація до 200 сторінок.</li>
                    <li>Визначення і використання до 350 ключових слів.</li>
                    <li>Місячний звіт про просування.</li>
                    <li>Основний аудит сайту.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <div class="card">
              <img
                class="card-img-top"
                src="assets/img/marketing/giraffe-2.png"
                alt=""
              />
              <div class="card mb-4">
                <div class="card-header bg-transparent">
                  <h3 class="fw-bold mb-0 bg-opacity">
                    <span>"Бізнес" 16 тис. грн/міс</span>
                  </h3>
                </div>

                <div class="card-body">
                  <h5 class="fw-bold">Підходить для:</h5>
                  <ul class="mb-3">
                    <li>
                      Середніх компаній або веб-сайтів зі зростаючою
                      популярністю.
                    </li>
                    <li>Бізнесів, які хочуть активно конкурувати на ринку.</li>
                  </ul>

                  <h5 class="fw-bold">Основні характеристики:</h5>
                  <ul>
                    <li>Оптимізація до 400 сторінок.</li>
                    <li>Визначення і використання до 700 ключових слів.</li>
                    <li>Двотижневі звіти про просування.</li>
                    <li>Поглиблений аудит сайту.</li>
                    <li>Основний конкурентний аналіз.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <div class="card">
              <img
                class="card-img-top"
                src="assets/img/marketing/giraffe-3.png"
                alt=""
              />
              <div class="card mb-4">
                <div class="card-header bg-transparent">
                  <h3 class="fw-bold mb-0 bg-opacity">
                    <span>"Преміум" 32 тис. грн/міс</span>
                  </h3>
                </div>

                <div class="card-body">
                  <h5 class="fw-bold">Підходить для:</h5>
                  <ul class="mb-3">
                    <li>
                      Великих корпоративних сайтів або великих онлайн магазинів.
                    </li>
                    <li>Компаній, які хочуть домінувати у своєму секторі.</li>
                  </ul>

                  <h5 class="fw-bold">Основні характеристики:</h5>
                  <ul>
                    <li>Оптимізація 1000+ сторінок.</li>
                    <li>Визначення і використання 1500+ ключових слів.</li>
                    <li>Тижневі звіти про просування.</li>
                    <li>Комплексний аудит сайту.</li>
                    <li>Розширений конкурентний аналіз.</li>
                    <li>Пріоритетний підбір посилань.</li>
                    <li>Персональний менеджер проекту.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Marketing;
