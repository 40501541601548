import React, { useState } from 'react';
import FeedbackModal from '../components/FeedbackModal';

function MainMenu() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light sticky-top"
      data-navbar-on-scroll="data-navbar-on-scroll"
      style={{
        backgroundImage: 'none',
        backgroundColor: 'rgba(249, 250, 253, 0.843)',
      }}
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="assets/img/logo-vertex.png" height="31" alt="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"> </span>
        </button>
        <div
          className="collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#feature">
                Переваги
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#validation">
                Як ми працюємо
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#superhero">
                Контакти
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#marketing">
                Ціни
              </a>
            </li>
          </ul>
          <div className="d-flex ms-lg-4">
            <a className="btn btn-secondary-outline" href="tel: +380509902641">
              Дзвінок
            </a>
            <a class="btn btn-warning ms-3" href="#!" onClick={openModal}>
              Співпраця
            </a>
            <FeedbackModal isOpen={isModalOpen} onRequestClose={closeModal} />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MainMenu;
