import React, { useState } from 'react';
import FeedbackModal from '../components/FeedbackModal';

function Advantages() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <section class="pt-5 pt-md-9 mb-6" id="feature">
      <div
        class="bg-holder z-index--1 bottom-0 d-none d-lg-block"
        style={{
          backgroundImage: 'url(assets/img/category/shape.png)',
          opacity: 0.5,
        }}
      ></div>

      <div class="container">
        <h1 class="fs-9 fw-bold mb-4 text-center">
          {' '}
          Ключові переваги <br class="d-none d-xl-block" />
          співпраці з нами
        </h1>
        <div class="row">
          <div class="col-lg-3 col-sm-6 mb-2">
            {' '}
            <img
              class="mb-3 ms-n3"
              src="assets/img/category/icon1.png"
              width="75"
              alt="Feature"
            />
            <h4 class="mb-3">Технології</h4>
            <p class="mb-0 fw-medium text-secondary">
              Використання найновіших технологій та забезпечення якісного
              результату
            </p>
          </div>
          <div class="col-lg-3 col-sm-6 mb-2">
            {' '}
            <img
              class="mb-3 ms-n3"
              src="assets/img/category/icon2.png"
              width="75"
              alt="Feature"
            />
            <h4 class="mb-3">Гнучкість</h4>
            <p class="mb-0 fw-medium text-secondary">
              Гнучкість та швидкість реагування на зміни в галузі
            </p>
          </div>
          <div class="col-lg-3 col-sm-6 mb-2">
            {' '}
            <img
              class="mb-3 ms-n3"
              src="assets/img/category/icon3.png"
              width="75"
              alt="Feature"
            />
            <h4 class="mb-3">Розуміння</h4>
            <p class="mb-0 fw-medium text-secondary">
              Індивідуальний підхід до проекту
            </p>
          </div>
          <div class="col-lg-3 col-sm-6 mb-2">
            {' '}
            <img
              class="mb-3 ms-n3"
              src="assets/img/category/icon4.png"
              width="75"
              alt="Feature"
            />
            <h4 class="mb-3">Розвиток</h4>
            <p class="mb-0 fw-medium text-secondary">
              Постійний пошук нових можливостей для розвитку вашого бізнесу
            </p>
          </div>
        </div>
        <div class="text-center">
          <a
            className="btn btn-warning"
            href="#!"
            role="button"
            onClick={openModal}
          >
            Співпраця
          </a>
          <FeedbackModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </div>
      </div>
    </section>
  );
}

export default Advantages;
