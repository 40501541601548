import React, { useState } from 'react';
import FeedbackModal from '../components/FeedbackModal';
import LegalModal from '../components/LegalModal';

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [isLegalModalOpen, setIsLegalModalOpen] = useState(false); // 2. Додати state
  const [legalContent, setLegalContent] = useState({ title: '', text: '' });

  const openLegalModal = (title, text) => {
    // 3. Змінити функції
    setLegalContent({ title, text });
    setIsLegalModalOpen(true);
  };

  const closeLegalModal = () => {
    setIsLegalModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    openModal();
  };
  return (
    <div>
      <section class="pb-2 pb-lg-5">
        <div class="container">
          <div class="row border-top border-top-secondary pt-7">
            <div class="col-lg-3 col-md-6 mb-4 mb-md-6 mb-lg-0 mb-sm-2 order-1 order-md-1 order-lg-1">
              <img
                class="mb-4"
                src="assets/img/logo-vertex.png"
                width="184"
                alt=""
              />
            </div>
            <div class="col-lg-3 col-md-6 mb-4 mb-lg-0 order-3 order-md-3 order-lg-2">
              <p class="fs-2 mb-lg-4">Швидкі посилання</p>
              <ul class="list-unstyled mb-0">
                <li class="mb-1">
                  <a
                    class="link-900 text-secondary text-decoration-none"
                    href="#feature"
                  >
                    Переваги
                  </a>
                </li>
                <li class="mb-1">
                  <a
                    class="link-900 text-secondary text-decoration-none"
                    href="#validation"
                  >
                    Як ми працюємо
                  </a>
                </li>
                <li class="mb-1">
                  <a
                    class="link-900 text-secondary text-decoration-none"
                    href="#superhero"
                  >
                    Контакти
                  </a>
                </li>
                <li class="mb-1">
                  <a
                    class="link-900 text-secondary text-decoration-none"
                    href="#marketing"
                  >
                    Ціни
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 mb-4 mb-lg-0 order-4 order-md-4 order-lg-3">
              <p class="fs-2 mb-lg-4">Юридичні речі</p>
              <ul class="list-unstyled mb-0">
                <li class="mb-1">
                  <a
                    onClick={() =>
                      openLegalModal(
                        'Відмова від відповідальності',
                        `Наша агенція надає рекомендації та послуги з просування в Інтернеті на основі багаторічного досвіду та професійної експертизи. Проте необхідно розуміти, що просування веб-сайтів — це складний процес, який залежить від безлічі зовнішніх і внутрішніх факторів.

                        Пошукові системи, такі як Google, регулярно оновлюють свої алгоритми. Ці зміни можуть впливати на позиціонування сайту в пошукових результатах. Тому ми не можемо гарантувати конкретних результатів просування.

                        Крім того, як підтверджує сама Google, жодна SEO агенція не може гарантувати певне місце в пошуковій видачі або певний обсяг трафіку. Навіть співробітники Google не можуть передбачити або гарантувати, як конкретний сайт буде ранжуватися в пошуковій системі.

                        Ми завжди прагнемо до найкращих результатів для наших клієнтів, використовуючи передові методики та підходи. Проте ми також бажаємо, щоб наші клієнти були в курсі всіх аспектів цього процесу та розуміли його особливості.`
                      )
                    }
                    class="link-900 text-secondary text-decoration-none"
                    href="#!"
                  >
                    Відмова від відповідальності
                  </a>
                </li>
                <li class="mb-1">
                  <a
                    class="link-900 text-secondary text-decoration-none"
                    href="#!"
                    onClick={() =>
                      openLegalModal(
                        'Повернення коштів',
                        `На жаль, ми не повертаємо кошти за надані послуги. Проте наша основна мета — задоволеність клієнта, тому якщо ви вважаєте, що отримані результати не відповідають вашим очікуванням, будь ласка, зверніться до нас.\n\nУ випадку підтвердження обґрунтованих претензій до якості наших послуг, ми зобов'язуємося надавати безкоштовні послуги протягом одного місяця, щоб виправити ситуацію та досягти бажаних результатів.`
                      )
                    }
                  >
                    Повернення коштів
                  </a>
                </li>
                <li class="mb-1">
                  <a
                    class="link-900 text-secondary text-decoration-none"
                    href="#!"
                    onClick={() =>
                      openLegalModal(
                        'Політика конфіденційності',
                        `Особиста інформація: Ми поважаємо вашу конфіденційність та гарантуємо, що ваші особисті дані знаходяться в повній безпеці. Ваші дані будуть використовуватися виключно для надання вам послуг та покращення нашого сервісу.

                        Передача даних: Ми не передаємо вашу інформацію третім особам без вашого письмового дозволу, за винятком випадків, передбачених законодавством.
                    
                        Захист даних: Ми використовуємо передові технології та методики, щоб забезпечити безпеку та конфіденційність вашої інформації від несанкціонованого доступу, втрати чи витоку.
                    
                        Кукі (Cookies): Ми можемо використовувати кукі для покращення вашого досвіду користування нашим сайтом. Ці файли не зберігають ваші особисті дані, а лише допомагають нам аналізувати користувацький досвід та оптимізувати наші послуги.
                    
                        Зміни в політиці: Ми можемо час від часу вносити зміни в цю політику. Рекомендуємо вам регулярно перевіряти цю сторінку, щоб бути в курсі оновлень.`
                      )
                    }
                  >
                    Політика конфіденційності
                  </a>
                </li>
                <li class="mb-1">
                  <a
                    class="link-900 text-secondary text-decoration-none"
                    href="#!"
                    onClick={() =>
                      openLegalModal(
                        'Умови використання',
                        `Прийняття умов: Користуючись нашим сайтом або послугами, ви погоджуєтеся дотримуватися цих умов використання. Якщо ви не погоджуєтеся з будь-яким пунктом цих умов, будь ласка, не користуйтеся нашим сайтом або послугами.

                        Вміст сайту: Весь контент на нашому сайті є інтелектуальною власністю агенції та захищений законодавством про авторські права. Будь-яке незаконне використання чи копіювання заборонено.
                    
                        Обмеження відповідальності: Ми намагаємося забезпечити точність та актуальність інформації на нашому сайті, але не несемо відповідальності за будь-які збитки або проблеми, що виникли в результаті використання нашого сайту або послуг.
                    
                        Зміни в умовах: Ми можемо вносити зміни в ці умови використання без попереднього повідомлення. Ваше продовжене використання сайту після таких змін вважається прийняттям нових умов.
                    
                        Посилання на інші сайти: Наш сайт може містити посилання на інші веб-сайти. Ми не несемо відповідальності за вміст або політику конфіденційності таких сайтів.`
                      )
                    }
                  >
                    Умови використання
                  </a>
                </li>
              </ul>
            </div>
            <LegalModal
              isOpen={isLegalModalOpen}
              onClose={closeLegalModal}
              content={legalContent}
            />
            <div class="col-lg-3 col-md-6 col-6 mb-4 mb-lg-0 order-2 order-md-2 order-lg-4">
              <p class="fs-2 mb-lg-4">
                Не сумнівайтеся, ми запропонуємо вам найкращу угоду!
              </p>
              <form class="mb-3" onSubmit={handleSubmit}>
                <input
                  class="form-control"
                  type="tel"
                  placeholder="Введіть свій номер телефону"
                  aria-label="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </form>
              <button
                class="btn btn-warning fw-medium py-1"
                onClick={openModal}
              >
                Співпраця
              </button>
              <FeedbackModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                initialPhone={phone}
              />
            </div>
          </div>
        </div>
      </section>

      <section class="text-center py-0">
        <div class="container">
          <div class="container border-top py-3">
            <div class="row justify-content-between">
              <div class="col-12 col-md-auto mb-1 mb-md-0">
                <p class="mb-0">&copy; 2023 SEO Vertex </p>
              </div>
              <div class="col-12 col-md-auto">
                <p class="mb-0">
                  Made with<span class="fas fa-heart mx-1 text-danger"> </span>
                  by{''}
                  <a class="text-decoration-none ms-1" href="/">
                    SEO Vertex
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
