import React from 'react';

function Tools() {
  return (
    <div>
      <section class="pt-5" id="validation">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <h5 class="text-secondary">Початок співпраці</h5>
              <h2 class="mb-2 fs-7 fw-bold">Старт вашого проекту</h2>
              <p class="mb-4 fw-medium text-secondary">
                Здійснення предоплати, підписання договору (за необхідності) та
                обговорення вашого проекту для початку роботи.
                <br />
                Визначення основних цілей і завдань.
              </p>
              <h4 class="fs-1 fw-bold">Прозорість</h4>
              <p class="mb-4 fw-medium text-secondary">
                У нас прозорі умови співпраці, що допомагає уникнути
                <br />
                будь-яких непорозумінь.
              </p>
              <h4 class="fs-1 fw-bold">Ефективні методи</h4>
              <p class="mb-4 fw-medium text-secondary">
                Якщо ви шукаєте новий спосіб просування вашого бізнесу,
                <br />
                який не потребує великих інвестицій,
              </p>
              <h4 class="fs-1 fw-bold">Індивідуальний підхід</h4>
              <p class="mb-4 fw-medium text-secondary">
                Якщо ви шукаєте унікальний спосіб виділитися серед конкурентів,
                <br />
                ми готові вам допомогти.
              </p>
            </div>

            <div class="col-lg-6">
              <img
                class="img-fluid"
                src="assets/img/validation/validation.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section class="pt-5" id="manager">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <img
                class="img-fluid"
                src="assets/img/manager/manager.png"
                alt=""
              />
            </div>
            <div class="col-lg-6">
              <h5 class="text-secondary">Взаємодія з клієнтом</h5>
              <p class="fs-7 fw-bold mb-2">Звітність та підтримка</p>
              <p class="mb-4 fw-medium text-secondary">
                Регулярні звіти про прогрес вашого проекту. Ми завжди готові
                відповісти на ваші питання та допомогти.
                <br />
                Ваш успіх та комфорт — наш головний пріоритет.
              </p>
              <div class="d-flex align-items-center mb-3">
                {' '}
                <img
                  class="me-sm-4 me-2"
                  src="assets/img/manager/tick.png"
                  width="35"
                  alt="tick"
                />
                <p class="fw-medium mb-0 text-secondary">
                  Прозора система звітності.
                  <br />
                  Гарантуємо оперативність і якість інформації.
                </p>
              </div>
              <div class="d-flex align-items-center mb-3">
                {' '}
                <img
                  class="me-sm-4 me-2"
                  src="assets/img/manager/tick.png"
                  width="35"
                  alt="tick"
                />
                <p class="fw-medium mb-0 text-secondary">
                  Індивідуальний підхід до кожного клієнта.
                  <br />
                  Ваші побажання та вимоги завжди на першому місці.
                </p>
              </div>
              <div class="d-flex align-items-center mb-3">
                <img
                  class="me-sm-4 me-2"
                  src="assets/img/manager/tick.png"
                  width="35"
                  alt="tick"
                />
                <p class="fw-medium mb-0 text-secondary">
                  Ми щоденно відстежуємо ринок та знаємо, де можна
                  <br />
                  зекономити.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-5" id="marketer">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <h5 class="text-secondary">Процес оптимізації</h5>
              <p class="mb-2 fs-8 fw-bold">Отримайте результат</p>
              <p class="mb-4 fw-medium text-secondary">
                Наша ціль - підняти ваш сайт на вершину пошукових систем.
                <br />
                Зазвичай, перші результати видно через 3-4 місяці.
                <br />
                Кожен проект для нас унікальний, тому ми звертаємо увагу на
                деталі та індивідуальні потреби клієнта.
              </p>
              <h4 class="fw-bold fs-1">Професійний підхід</h4>
              <p class="mb-4 fw-medium text-secondary">
                Ми використовуємо найновітніші методи SEO для досягнення
                <br />
                найкращих результатів. На вас чекає команда експертів.
              </p>
              <h4 class="fw-bold fs-1">Транспарентність у роботі</h4>
              <p class="mb-4 fw-medium text-secondary">
                Наші клієнти завжди в курсі всіх етапів оптимізації.
                <br />
                Ми надаємо чіткі звіти та консультації.
              </p>
              <h4 class="fw-bold fs-1">Довгострокова співпраця</h4>
              <p class="mb-4 fw-medium text-secondary">
                Ми цінуємо кожного клієнта і прагнемо до довгострокових
                відносин.
                <br />
                Ваш успіх - наш приоритет.
              </p>
            </div>

            <div class="col-lg-6">
              <img
                class="img-fluid"
                src="assets/img/marketer/marketer.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Tools;
