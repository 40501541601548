import React, { useState } from 'react';
import FeedbackModal from '../components/FeedbackModal';

function Hero() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <section class="py-md-11 py-8" id="superhero">
      <div
        class="bg-holder z-index--1 bottom-0 d-none d-lg-block background-position-top"
        style={{
          backgroundImage: 'url(assets/img/superhero/oval.png)',
          opacity: 0.5,
          backgroundPosition: 'top !important',
        }}
      ></div>

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <h1 class="fw-bold mb-4 fs-7">Вам потрібен супергерой?</h1>
            <p class="mb-5 text-info fw-medium">
              Надішліть запит на допомогу: Ключові слова,
              <br />
              SEO-аудит, внутрішня оптимізація, контент-стратегія, аналіз
              конкурентів, посилання.
            </p>
            <a
              class="btn btn-warning"
              href="#!"
              className="btn btn-warning btn-md"
              onClick={openModal}
            >
              Зв'яжіться з нашим експертом
            </a>
            <FeedbackModal isOpen={isModalOpen} onRequestClose={closeModal} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
